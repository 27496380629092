import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';

import { SharedModule } from '../company/modules/shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { BubblesComponent } from './components/bubbles/bubbles.component';
import { CenterComponent } from './components/center/center.component';
import { MessengerLauncherComponent } from './components/messenger-launcher/messenger-launcher.component';
import { MessengerComponent } from './components/messenger/messenger.component';

@NgModule({
  declarations: [
    MessengerComponent,
    MessengerLauncherComponent,
    BubblesComponent,
    CenterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiModule,
    SharedModule,
    NgPipesModule,
    FormsModule
  ],
  exports: [
    MessengerComponent,
    MessengerLauncherComponent
  ]
})
export class ChatModule { }
