<div class="m-row small text-center helper no-select">{{ 'MESSENGER.TRACK_RECORD'|translate }}</div>
<ng-container *ngFor="let message of room.messages; let messageIndex = index; trackBy: trackById">
  <ng-container *ngIf="(message.text && message.text !== '') || message.file">
    <div class="m-row m-center" *ngIf="displayDate(messageIndex)">
      <!-- Date -->
      <div class="m-date text-muted text-uppercase">
        <ng-container
          *ngIf="((message.created_at | date:('GLOBAL.DATE_FORMAT.RG'|translate)) === (todayISO | date:('GLOBAL.DATE_FORMAT.RG'|translate))); then today; else date">
        </ng-container>
        <ng-template #today>{{ 'MESSENGER.TODAY'|translate }}</ng-template>
        <ng-template #date>{{ message.created_at | date:('GLOBAL.DATE_FORMAT.RG'|translate)}}</ng-template>
      </div>
    </div>
    <div class="m-row" *ngIf="user && company">
      <!-- Message -->
      <div class="m-bubble"
      [class.in]="message.user.id !== user.id || message.company.id !== company.id"
      [class.out]="message.user.id === user.id && message.company.id === company.id"
      [class.sending]="message.sending"
      [class.same-user]="messageIndex > 0 &&
      !displayDate(messageIndex) &&
      (message.user.id === room.messages[messageIndex - 1].user.id && message.company.id === room.messages[messageIndex - 1].company.id)">
        <div class="m-text">
          <div class="m-user" [style.color]="getUserColor(message.user.id + '-' + message.company.id)">{{ message.user.name }} {{ message.user.lastName }}
            <div *ngIf="room.companies.length > 1"
            class="small lighten text-muted">{{ message.company.name }}</div>
          </div>
          <span *ngIf="message.text" [innerHtml]="message.text|userMessage"></span>
          <div *ngIf="message.file" class="m-attachment"
          (click)="preview(message.file)"
          title="{{ message.file.name }}">
            <span class="m-ico m-type glyphicon {{ glyphiconClass(message.file.type) }}"></span>
            <div class="m-filename truncate">
              {{ message.file.name }}
              <div class="micro text-muted">{{ message.file.size|filesize }}</div>
            </div>
            <!-- <span class="glyphicon glyphicon-download"></span> -->
            <i class="m-ico material-symbols-rounded">arrow_downward</i>
          </div>
          <div class="m-time">{{ message.created_at | date: 'HH:mm' }}</div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ag-file-preview #filePreviewer></ag-file-preview>