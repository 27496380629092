<button type="button"
class="btn btn-link" 
(click)="messenger.toggle($event)"
[disabled]="!messenger?.ready || messenger?.processing"
title="{{ 'MESSENGER.LAUNCHER_TITLE'|translate }}">
  <!-- Unread bullet indicator -->
  <div *ngIf="messenger?.unread" class="unread" title="{{ 'MESSENGER.UNREAD_BULLET'|translate }}"></div>
  <i class="material-symbols-rounded">chat</i>
  <!-- {{ 'NEGOTIATION_ACTIONS.CHAT'|translate }} -->
  <div class="floating-legend hidden-xs"
  *ngIf="messenger?.ready && messenger.rooms[0].id === undefined"
  [class.hidden]="messenger.isOpen">
    <div class="clip">
      <!-- We should show this only when there is no messeges -->
      <div class="cta">{{ 'MESSENGER.LAUNCHER_TITLE'|translate }}</div>
      <i class="material-symbols-rounded">keyboard_arrow_right</i>
    </div>
  </div>
</button>