import { Type } from "class-transformer";

import { User } from "../../auth/models/user.model";
import { Company } from "../../models/company.model";

/**
 * Represents a file associated with a chat message.
 */
export class ChatFile {
  /**
   * The unique identifier of the file.
   * @readonly
   */
  readonly id?: string;
  /** The name of the file. */
  name: string;
  /** The size of the file in bytes. */
  size: number;
  /** The MIME type of the file. */
  type: string;
}

/**
 * Represents a chat message.
 */
export class ChatMessage {
  /** The text content of the chat message. */
  text?: string;
  /** The file associated with the chat message. */
  file?: ChatFile;

  /**
   * The date and time when the message was created.
   * @readonly
   */
  @Type(() => Date)
  readonly created_at: Date;

  /**
   * The user who sent the chat message.
   * @readonly
   */
  readonly user: User;

  /**
   * The Company associated with the chat message.
   * @readonly
   */
  @Type(() => Company)
  readonly company: Company;

  /** Indicates whether the message is currently being sent. */
  sending?: boolean;
}