import { DOCUMENT, Location } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { plainToInstance } from 'class-transformer';

import { environment } from '../../../../environments/environment';
import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { PusherService } from '../../../services/pusher.service';
import { checkAccept } from '../../../utilities/file';
import { SubscriptionManager } from '../../../utilities/subscription-manager';
import { ChatMessage } from '../../models/chat-message.model';
import { ChatRoom } from '../../models/chat-room.model';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'ag-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent extends SubscriptionManager implements OnDestroy, OnChanges {

  @ViewChild('dropArea') private readonly dropArea: ElementRef;
  @ViewChild('dropLegend') private readonly dropLegend: ElementRef;
  @ViewChild('scrollMe') private readonly scrollMe: ElementRef;

  @Input() public company: Company;
  @Input() public set companies(value: Company[]) {
    this._companies = [...value.reduce((a, c) => {
      if (c?.id) {
        a.set(c.id, c);
        return a;
      }
    }, new Map()).values()];
  }

  /**
   * Entity unique identifier.
   * 
   * This attribute in combination with the [[entity|entity type]] is the
   * unique reference to the chat context.
   * 
   * This attribute is required.
   */
  @Input('entity-id') public entity_id: number | string;
  /**
   * Entity type. Eg: order, barter, etc.
   * 
   * This attribute is required.
   */
  @Input() public entity: string;
  @Input('enable-attachments') public enableAttachments: boolean = true;

  public get companies(): Company[] { return this._companies; }

  public set isOpen(flag: boolean) {
    this._isOpen = flag;

    if (flag) this.document.body.classList.add(this.bodyClassName);
    else this.document.body.classList.remove(this.bodyClassName);
  }
  public get isOpen(): boolean {
    return this._isOpen;
  }

  /**
   * Specify what file types the user can attach to a chat.
   * 
   * Works as
   * [[https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|HTML attribute: accept]].
   */
  public accept: string = "";
  public chatHeight: number = 0;
  public newMessage: string; // Input ngModel
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public ready: boolean;
  public roomIndex: number; // this.rooms index
  public rooms: ChatRoom[] = [];
  public unread: boolean;
  /** Current [[User]]. */
  public user: User;

  private _companies: Company[];
  private _isOpen: boolean;
  private _inited: boolean;
  private bodyClassName: string = 'messenger-open';

  constructor(
    private location: Location,
    private pusherService: PusherService,
    private chatService: ChatService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.roomIndex = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (environment.modules.chat &&
      !this._inited &&
      this.company &&
      this.company.market.configuration.order.messenger.enabled &&
      this.entity &&
      this.entity_id &&
      this.isMyCompanyIncluded()) {
      this.init();
    }
  }

  private isMyCompanyIncluded(): boolean {
    // Utilizo some para detener la búsqueda tan pronto como se encuentra una coincidencia
    return this.companies.some(company => company.id === this.company.id);
  }

  private init(): void {
    this._inited = true;

    // Obtengo el Usuario
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));

    // Suscripción a cambios en la URL
    this.subscriptions.push(this.route.parent.queryParams.subscribe(params => {
      this.isOpen = params['messenger'] && params['messenger'] === 'open' ? true : false;
    }));

    this.loadRoom();
  }

  /** Cargo los rooms al inicializar */
  private loadRoom(): void {
    this.subscriptions.push(this.chatService.get(this.company.id, {
      entity: this.entity,
      entity_id: this.entity_id
    }, false).subscribe(response => {
      this.setRooms(response.body);
    }));
  }

  public switchRoom(index: number): void {
    this.roomIndex = index;

    const room = this.rooms[this.roomIndex];
    if (room.messages?.length === 1) this.getRoom(room.id); // Cargo el Room activo
  }

  /** Parseo la informacion de rooms cargada. */
  private setRooms(rooms?: ChatRoom[]): void {
    if (rooms?.length > 0) {
      // Existen rooms
      this.rooms = rooms;

      const room = this.rooms[this.roomIndex];
      if (room.messages?.length === 1) this.getRoom(room.id); // Cargo el Room activo
    } else {
      // Se crea un room temporal (sin id)
      this.rooms.push(new ChatRoom({
        entity: this.entity,
        entity_id: this.entity_id + '',
        companies: this.companies
      }));
    }

    // Escucho evento Pusher para novedades
    this.subscriptions.push(this.pusherService.listen('company_' + this.company.id, 'chat').subscribe(event => {
      // Update only when this id changed
      if (event.data &&
        (event.data.entity === this.entity && event.data.entity_id === this.entity_id)) {
        this.eventListener(event.data);
      }
    }));

    this.ready = true;
    setTimeout(() => {
      this.setupDrop(this.dropArea); // Initialize drag and drop
    });
    this.focus(100);
    this.updateChatHeight();
  }

  private eventListener(data: any): void {
    const roomIndex = this.rooms.findIndex(room => room.id === data.room_id); // Busco si existe el room del evento en memoria

    if (roomIndex === -1) {
      // Si no existe, cargo todo el canal
      this.getRoom(data.room_id);
    } else {
      // Ya tengo el canal cargado, agrego el mensaje
      if (data.message) {
        this.rooms[roomIndex].messages = this.rooms[roomIndex].messages.filter(message => !message.sending);
        this.rooms[roomIndex].messages.push(plainToInstance(ChatMessage, data.message));

        if (roomIndex === this.roomIndex) this.updateChatHeight();
      }
      if (data.context) {
        this.rooms[roomIndex].context = data.context;
      }
    }

    if (!this.isOpen) this.toggle();
  }

  private focus(delay: number = 0): void {
    if (this.isOpen) {
      setTimeout(() => {
        const input = this.document.getElementById('inputElement');
        if (input) input.focus();
      }, delay);
    }
  }

  private getRoom(roomId: string): void {
    this.processing = true;

    this.chatService.getRoom(this.company.id, roomId).subscribe(room => {
      const index = this.rooms.findIndex(savedRoom => savedRoom.id === room.id);

      if (index !== -1) this.rooms[index] = room; // Si exsite, actualizo
      else this.rooms.push(room); // Si es nuevo, lo agrego

      // Esto implica que existe al menos un room
      // Si se creo algun room temporal (sin id) se elimina
      this.rooms = this.rooms.filter(room => room.id !== undefined);
      this.updateChatHeight();
      this.processing = false;
    });
  }

  private setReaded(): void {
    const room = this.rooms[this.roomIndex];

    if (this.isOpen && room.setReaded(this.user)) {
      this.chatService.context(this.company.id, room).subscribe(context => {
        this.rooms[this.roomIndex].context = context;
      });
    }
  }

  public close(): void {
    if (this.isOpen) this.toggle();
  }

  public toggle(event?: Event): void {
    if (event) event.stopPropagation(); // This prevents the execution of onClickOutside

    this.isOpen = !this.isOpen;

    const path = this.location.path().split("?");
    if (this.isOpen) {
      this.location.go(path[0], 'messenger=open');
      this.focus();
      this.updateChatHeight();
    } else {
      this.location.go(path[0]);
    }
  }

  public submit(fileList?: FileList): void {
    this.processing = true;
    const room = this.rooms[this.roomIndex];
    const fileArray = fileList ? Array.from(fileList) : undefined;

    if (room.id) this.send(fileArray);
    else {
      // Primero debo crear el Room
      this.subscriptions.push(this.chatService.create(this.company.id, room).subscribe(newRoom => {
        this.rooms[this.roomIndex] = newRoom;
        this.send(fileArray);
      }));
    }
  }

  private send(fileArray?: File[]): void {
    const phantomMessage: ChatMessage = {
      user: this.user,
      company: this.company,
      created_at: new Date(),
      sending: true
    };

    if (fileArray?.length > 0) {
      // Primero, debo enviar archivos
      const file = fileArray.shift();

      this.rooms[this.roomIndex].messages.push({
        file: {
          name: file.name,
          type: file.type,
          size: file.size
        }, ...phantomMessage
      });

      this.subscriptions.push(this.chatService.uploadFile(this.company.id, this.rooms[this.roomIndex].id, file).subscribe(message => {
        this.send(fileArray);
      }));
    } else if (this.newMessage && this.newMessage.trim() !== "") {
      this.rooms[this.roomIndex].messages.push({ text: this.newMessage, ...phantomMessage });

      this.subscriptions.push(this.chatService.send(this.company.id, this.rooms[this.roomIndex].id, this.newMessage).subscribe(message => {
        this.newMessage = undefined;
        this.processing = false;
      }));
    } else {
      this.processing = false;
    }
    this.updateChatHeight();
  }

  private updateChatHeight(): void {
    if (this.isOpen) {
      this.setReaded();
      setTimeout(() => {
        // Scroll pane down to the last message
        if (this.scrollMe) this.chatHeight = this.scrollMe.nativeElement.scrollHeight;
      });
    }
    this.checkUnread();
  }

  private checkUnread(): void {
    this.unread = false;

    for (let index = 0; index < this.rooms.length; index++) {
      const room = this.rooms[index];

      if (room.hasUnread(this.user)) {
        this.unread = true;
        break;
      }
    }
  }

  public changeFile(event): void {
    // Triggered by input component
    this.submit(event.target.files);
    event.target.value = ""; // Reset the input
  }

  private setupDrop(elSource: ElementRef): void {
    if (this.enableAttachments && elSource) {
      const el = elSource.nativeElement;

      el.addEventListener("dragenter", this.dragger, false);
      el.addEventListener("dragover", this.dragger, false);
      el.addEventListener("drop", this.drop, false);
      el.addEventListener("dragleave", this.removeDrag, false);
      el.addEventListener("dragend", this.removeDrag, false);
    }
  }

  private killDrop(elSource: ElementRef): void {
    if (elSource) {
      const el = elSource.nativeElement;

      // Removes all event listeners
      el.removeEventListener("dragenter", this.dragger);
      el.removeEventListener("dragover", this.dragger);
      el.removeEventListener("drop", this.drop);
      el.removeEventListener("dragleave", this.removeDrag);
      el.removeEventListener("dragend", this.removeDrag);
    }
  }

  private dragger = (e: Event) => {
    if (!this.processing) {
      e.stopPropagation();
      e.preventDefault();
      if (this.dropLegend) this.dropLegend.nativeElement.className = 'is-dragging';
    }
  };

  private drop = (e: DragEvent) => {
    if (!this.processing) {
      this.dragger(e);

      // Triggered by drop
      // Prevent to drag more files when the max files number is reached
      if (checkAccept(e.dataTransfer.files, this.accept)) this.submit(e.dataTransfer.files);
      // }
      this.removeDrag();
    }
  };

  private removeDrag = (e?: DragEvent) => {
    if (this.dropLegend) this.dropLegend.nativeElement.className = '';
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.killDrop(this.dropArea);

    // Delete the company from contextInfo
    // if (this.contextInfo && this.company && this.account) this.chatService.setPropertiesInContext(this.contextInfo.id, this.company.id, this.account.id, false);
    // Unsubscribe from everything
    // if (this.roomsSubscriptions) this.roomsSubscriptions.unsubscribe();
    super.ngOnDestroy();
  }
}
