import { Component, Input } from '@angular/core';

import { MessengerComponent } from '../messenger/messenger.component';

@Component({
  selector: 'ag-messenger-launcher',
  templateUrl: './messenger-launcher.component.html',
  styleUrls: ['./messenger-launcher.component.scss']
})
export class MessengerLauncherComponent {

  @Input() public messenger: MessengerComponent;

  constructor() { }

}
