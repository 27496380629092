<div #dropArea class="messenger-panel"
[class.hidden]="!isOpen || !(rooms?.length > 0)"
*ngIf="ready"
(clickOutside)="close()">
  <div class="messenger-body backdrop-glass">
    <button type="button" class="btn btn-link"
    [disabled]="processing"
    (click)="close()">
      <span class="material-symbols-rounded">close</span>
    </button>

    <div #dropLegend id="dropLegend">
      <div>{{ 'MESSENGER.DROP_HERE'|translate }}</div>
    </div>
    <ng-container *ngIf="rooms">
      <div class="m-header">
        <div class="tablist no-select" *ngIf="rooms.length > 1">
          <ul class="inner">
            <li class="tablist__tab"
            [class.active]="roomIndex === i"
            *ngFor="let room of rooms; let i = index"
            (click)="switchRoom(i)">
              <!-- Unread bullet indicator -->
              <!-- <div *ngIf="false" class="unread" title="{{ 'MESSENGER.UNREAD_BULLET'|translate }}"></div> -->
              <ng-container *ngTemplateOutlet="companyLogos; context: {
                companies: room.companies
              }"></ng-container>
            </li>
            <li class="tablist__tab"></li><!-- This is always empty -->
          </ul>
        </div>
        <div class="m-company" *ngIf="rooms[roomIndex]?.companies; let members">
          <ng-container *ngIf="rooms.length < 2">
            <ng-container *ngTemplateOutlet="companyLogos; context: {
              companies: members
            }"></ng-container>
          </ng-container>
          <div class="names">
            <b class="truncate" title="{{ members|listFormatter: { excludeId: (members.length === 1 ? undefined : company.id) } }}">{{
              members|listFormatter: {excludeId: (members.length === 1 ? undefined : company.id) } }}</b>
            <ng-container *ngIf="members.length === 1">
              <!-- Online indicator -->
              <!-- <div class="indicator text-uppercase no-select"
              *ngIf="isCompanyInContext(members[0].id) && isCompanyOnline(members[0].id)">
                <span class="online animated blinking glyphicon glyphicon-record" aria-hidden="true"></span> {{ 'MESSENGER.ONLINE'|translate }}
              </div> -->
              <!-- Typing indicator -->
              <!-- <bouncer *ngIf="true"></bouncer> -->
            </ng-container>
          </div>
        </div>
      </div>
      <div class="m-messages" #scrollMe [scrollTop]="chatHeight">
        <ag-bubbles
        [room]="rooms[roomIndex]"
        [user]="user"
        [company]="company"></ag-bubbles>
        <bouncer *ngIf="processing"></bouncer>
      </div>
      <div class="m-footer">
        <form #messageForm="ngForm" (ngSubmit)="messageForm.valid && submit()">
          <input class="form-control" type="text" name="newMessage" [(ngModel)]="newMessage"
          maxlength="1024"
          autocomplete="off"
          [disabled]="processing"
          placeholder="{{ 'MESSENGER.PLACEHOLDER'|translate }}" required id="inputElement">
        </form>
        <div class="attach-div"
        [class.hidden]="!enableAttachments">
          <input type="file" name="attach" id="attachInput" (change)="changeFile($event)" [accept]="accept"
          [disabled]="processing"
          multiple="true">
          <label for="attachInput" title="{{ 'MESSENGER.ATTACH'|translate }}">
            <i class="material-symbols-rounded">attach_file</i>
          </label>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #companyLogos let-companies="companies">
  <div class="logos" [style.width]="((22 - logosOverlap) + ((companies.length - 1) * logosOverlap)) + 'px'">
    <ng-container *ngFor="let c of companies; let i = index">
      <div *ngIf="c.id !== company.id || companies.length === 1" class="company-logo"
        [style.backgroundImage]="c.logo|trustStyleUrl"></div>
    </ng-container>
  </div>
</ng-template>