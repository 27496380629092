<div class="container-fluid">
  <div class="content-box" #dropArea>
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>{{ 'MESSENGER_CENTER.TITLE'|translate }}</h3>
      </div>
      <div class="ag-header-actions">
        <add-phone [user]="user"></add-phone>
      </div>
    </div>

    <ng-container *ngIf="rooms; else loading">
      <div class="row" *ngIf="rooms.length > 0; else nodata">
        <div class="col-xs-12">
          <div class="messenger-container"
          [class.selected]="selectedIndex !== undefined">
            <div class="rooms">
              <!-- <div class="search">
                <input class="form-control" type="search" name="message_filter"
                [(ngModel)]="search_phrase"
                (ngModelChange)="searchChanged()" placeholder="{{ 'MESSENGER_CENTER.SEARCH_PLACEHOLDER'|translate }}">
              </div> -->
              <div class="room"
              *ngFor="let room of rooms; let roomIndex = index"
              [class.active]="selectedIndex !== undefined && rooms[selectedIndex].id === room.id"
              (click)="setRoom(roomIndex)">
                <ng-container *ngTemplateOutlet="roomItem; context: { room: room }"></ng-container>
              </div>
              <!-- <ng-container *ngIf="search_phrase === ''">
                <div class="room"
                *ngFor="let room of rooms"
                [class.active]="selected?.id === room.id"
                (click)="setRoom(room)">
                  <ng-container *ngTemplateOutlet="roomItem; context: {room: room, message: room.messages[room.messages.length - 1]}"></ng-container>
                </div>
              </ng-container>
              <div *ngIf="search_results.length === 0 && search_phrase !== ''" class="text-muted text-center no-results">
                {{ 'MESSENGER_CENTER.NO_RESULTS'|translate }}
              </div> -->
            </div>
            <div class="messenger-body">
              <div #dropLegend id="dropLegend">
                <div>{{ 'MESSENGER.DROP_HERE'|translate }}</div>
              </div>
              <ng-container *ngIf="selectedIndex !== undefined; else noRoom">
                <ng-container *ngIf="rooms[selectedIndex]; let room">
                  <div class="m-header">
                    <div class="b" (click)="setRoom(selectedIndex)">
                      <i class="material-symbols-rounded">arrow_back_ios</i>
                    </div>
                    <div class="logos hidden-xs" [style.width]="((49 - logosOverlap) + (room.companies.length * logosOverlap)) + 'px'">
                      <div *ngFor="let c of room.companies; let companyIndex = index" class="company-logo"
                      [title]="c.name"
                      [style.left]="(companyIndex * logosOverlap) + 'px'"
                      [style.backgroundImage]="c.logo|trustStyleUrl"></div>
                    </div>
                    <div class="m-header-text">
                      <a *ngIf="room.entityURI"
                      [routerLink]="['/company/' + company.id + room.entityURI.path]"
                      [queryParams]="{ 'messenger': 'open' }">
                        {{ room.entityURI.slug|translate }}
                        #{{ room.entity_id }}
                      </a>
                      <div class="names">
                        <b class="truncate" title="{{ room.companies|listFormatter }}">{{ room.companies|listFormatter }}</b>
                        <ng-container *ngIf="room.companies.length === 1">
                          <!-- Online indicator -->
                          <!-- <div class="indicator text-uppercase no-select" *ngIf="contextInfo?.companies[room.companies[0].id]?.online">
                            <span class="online animated blinking glyphicon glyphicon-record" aria-hidden="true"></span> {{ 'MESSENGER.ONLINE'|translate }}
                          </div> -->
                          <!-- Typing indicator -->
                          <!-- <bouncer *ngIf="true"></bouncer> -->
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="m-messages" #scrollMe [scrollTop]="chatHeight">
                    <ag-bubbles
                    [room]="room"
                    [user]="user"
                    [company]="company"></ag-bubbles>
                    <bouncer *ngIf="processing"></bouncer>
                  </div>
                  <div class="m-footer">
                    <form #messageForm="ngForm" (ngSubmit)="messageForm.valid && submit()">
                      <input class="form-control" type="text" name="newMessage" [(ngModel)]="newMessage[room.id]"
                      id="inputElement"
                      maxlength="1024"
                      autocomplete="off"
                      [disabled]="processing"
                      placeholder="{{ 'MESSENGER.PLACEHOLDER'|translate }}" required>
                    </form>
                    <div class="attach-div"
                    [class.hidden]="!enableAttachments">
                      <input type="file" name="attach" id="attachInput" (change)="changeFile($event)" [accept]="accept"
                      [disabled]="processing"
                      multiple="true">
                      <label for="attachInput" title="{{ 'MESSENGER.ATTACH'|translate }}">
                        <i class="material-symbols-rounded">attach_file</i>
                      </label>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #roomItem let-room="room">
  <div class="logos" [style.width]="((49 - logosOverlap) + (room.companies.length * logosOverlap)) + 'px'">
    <div *ngFor="let c of room.companies; let i = index" class="company-logo"
    [title]="c.name"
    [style.left]="(i * logosOverlap) + 'px'"
    [style.backgroundImage]="c.logo |trustStyleUrl"></div>
  </div>
  <!-- Use .unread for unread messages -->
  <div class="m-room" [class.unread]="unread[room.id]"
  *ngIf="room.messages[room.messages.length - 1]; let message">
    <div class="m-room-head">
      <div class="name truncate">
        <span  *ngIf="room.entityURI">
          {{ room.entityURI.slug|translate }}
          <!-- #{{ room.entity_id }} -->
        </span>
      </div>
      <div class="timestamp">
        {{ message.created_at | date : 'HH:mm' }}
        <span *ngIf="(message.created_at | date:'d/M/yyyy') !== (todayISO | date:'d/M/yyyy')">- {{ message.created_at | date : ('MESSENGER_CENTER.DATE_FORMAT'|translate) }}</span>
      </div>
    </div>
    <div class="m-room-content">
      <span class="n">{{ message.user.name + ' ' + message.user.last_name }}:</span>
      <span class="c">
        <span *ngIf="message.text" title="{{ message.text }}">{{ message.text }}</span>
        <span *ngIf="message.file"><span class="text-muted glyphicon glyphicon-file"></span> {{ message.file.name }}</span>
        <ng-template #fileMessage>
        </ng-template>
      </span>
      <!-- Number of unread messages -->
      <span class="u"></span>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #nodata>
  <div class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'MESSENGER_CENTER.EMPTY'|translate }}
  </div>
</ng-template>

<ng-template #noRoom>
  <div class="no-selected">
    <i class="material-symbols-rounded">chat</i>
    {{ 'MESSENGER_CENTER.SELECT_ROOM'|translate }}
  </div>  
</ng-template>