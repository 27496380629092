import { Component, Input, ViewChild } from '@angular/core';

import { User } from '../../../auth/models/user.model';
import { Company } from '../../../models/company.model';
import { CurrentDateService } from '../../../services/current-date.service';
import { FilePreviewComponent } from '../../../ui/components/file-preview/file-preview.component';
import { ChatFile, ChatMessage } from '../../models/chat-message.model';
import { ChatRoom } from '../../models/chat-room.model';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'ag-bubbles',
  templateUrl: './bubbles.component.html',
  styleUrls: ['./bubbles.component.scss']
})
export class BubblesComponent {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  @Input() public room: ChatRoom;
  /** Current [[User]]. */
  @Input() public user: User;
  @Input() public company: Company;
  @Input() public helper: string;

  public todayISO: string;

  // Assign users colors
  private users_colors: { [key: number]: string } = {};
  private currentUserColor: number = 0;
  private usersColors = [
    '#225f1e', // Dark green
    '#8732cd', // Purple
    '#e44436', // Red
    '#005888', // Blue
    '#ff9300', // Orange
    '#37aef3', // Light blue
    '#cd3292', // Pink
    '#3E8736', // Forest green
    '#9B5AC9', // Amethyst
    '#DC6B61', // Terra cotta
    '#1677B0', // Star command blue
    '#EFA039', // Yellow Orange
    '#66B4E4', // Aero
    '#CC5C9F' // Mulberry crayola
  ];

  constructor(
    private chatService: ChatService,
    private currentDate: CurrentDateService
  ) {
    this.todayISO = this.currentDate.get().toISOString();
  }

  public glyphiconClass(mime: string): string {
    let cl = "file", // Default
      has = function (str) {
        return (mime.indexOf(str) !== -1);
      };

    if (has('image/')) cl = "picture";
    else if (has('video/')) cl = "film";
    else if (has('audio/')) cl = "volume-up";
    else if (has('/zip') || has('-compressed')) cl = "compressed";

    return "glyphicon-" + cl;
  }

  public displayDate(i: number): boolean {
    if (this.room.messages[i - 1] && this.room.messages[i - 1].created_at) {
      const p = this.room.messages[i - 1].created_at.toISOString().split('T')[0],
        c = this.room.messages[i].created_at.toISOString().split('T')[0];

      return p !== c;
    } else return true;
  }

  public getUserColor(id: number): string {
    if (this.users_colors[id] === undefined) {
      this.users_colors[id] = this.usersColors[this.currentUserColor++];
      if (this.currentUserColor >= this.usersColors.length) this.currentUserColor = 0;
    }

    return this.users_colors[id];
  }

  /**
   * Avoid unnecessary rendering on ngFor
   */
  public trackById(index: number, item: ChatMessage): string {
    return (item.text || '') + '_' + (item.file || '');
  }

  /** Try to preview the file located at the specified index in the browser. */
  public preview(file: ChatFile): void {
    if (file.id) this.filePreviewer.preview(this.chatService.file(this.company.id, this.room.id, file.id), file.name, file.size);
  }
}
