import { Type } from "class-transformer";

import { User } from "../../auth/models/user.model";
import { Company } from "../../models/company.model";
import { ChatMessage } from "./chat-message.model";

/**
 * Represents a chat room with messages, associated companies, and context data.
 */
export class ChatRoom {
  /**
   * The unique identifier of the chat room.
   * @readonly
   */
  readonly id?: string;

  /** The entity type associated with the chat room. */
  entity: string;
  /** The unique identifier of the entity associated with the chat room. */
  entity_id: string;

  /** The companies associated with the chat room. */
  @Type(() => Company)
  companies: Company[];

  /** The context data for the chat room. */
  context?: { [key: string]: any; };

  /** The messages in the chat room. */
  @Type(() => ChatMessage)
  messages?: ChatMessage[] = [];

  /**
   * Gets the URI and slug for the entity associated with the chat room.
   * 
   * @returns {{ path: string; slug: string }} The path and slug for the entity.
   */
  public get entityURI(): {
    path: string;
    slug: string;
  } {
    let relativePath: string;
    let slug: string;

    switch (this.entity) {
      case 'doa':
        relativePath = 'approvals/' + this.entity_id;
        slug = 'DOA.APPROVAL';
        break;

      case 'financial-application':
        relativePath = 'fintech/applications/' + this.entity_id;
        slug = 'FINTECH.APPLY.TITLE';
        break;

      case 'auction':
        relativePath = 'order/' + this.entity_id;
        slug = 'GLOBAL.AUCTION';
        break;

      case 'barter':
        relativePath = 'barters/' + this.entity_id;
        slug = 'GLOBAL.BARTER';
        break;

      case 'order':
        relativePath = 'order/' + this.entity_id;
        slug = 'GLOBAL.ORDER';
        break;
    }

    if (relativePath) {
      return {
        path: '/' + relativePath,
        slug: slug
      };
    }
  }

  private readonly _user_timestamp_key = 'user_';

  /**
   * Sets the chat room as read for a user.
   * 
   * @param {User} user - The user for whom to set the chat room as read.
   * @returns {boolean} True if the chat room was marked as read, false otherwise.
   */
  public setReaded(user: User): boolean {
    const messages = this.messages.filter(message => !message.sending);

    if (this.id && messages?.length > 0) {
      const timestamp = messages[messages.length - 1].created_at.getTime();

      if (!this.context || this.context[this._user_timestamp_key + user.id] !== timestamp) {
        this.context = {
          ...(this.context || {}),
          [this._user_timestamp_key + user.id]: timestamp
        };

        return true;
      } else return false;
    } else return false;
  }

  /**
   * Checks if the chat room has unread messages for a user.
   * 
   * @param {User} user - The user to check for unread messages.
   * @returns {boolean} True if there are unread messages, false otherwise.
   */
  public hasUnread(user: User): boolean {
    if (this.id && this.messages?.length > 0) {
      const timestamp = this.messages[this.messages.length - 1].created_at.getTime();

      if (this.id && (!this.context || this.context[this._user_timestamp_key + user.id] !== timestamp)) {
        return true;
      } else return false;
    } else return false;
  }

  /**
   * Creates a new ChatRoom instance.
   * 
   * @param {Partial<ChatRoom>} [data={}] - The partial data to initialize the chat room.
   */
  constructor(data: Partial<ChatRoom> = {}) {
    Object.assign(this, data);
  }
}
